import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Col, Loading, Row } from '@commonsku/styles';

import DownloadArtifact from '../DownloadArtifact';

import { getBillingPeriods } from './selectors';
import { fetchBillingPeriods } from './dashboardSlice';

import { oauth } from '../../utils';

function ReportMessage({ children }) {
  return (
    <Col padded xs={12} lg={6}>
      <Box padded>
        <Row>
          <Col xs={12}>
            {children}
          </Col>
        </Row>
      </Box>
    </Col>
  );
}

const isLoading = ({ dashboard }) => dashboard.loading.billing_periods;

function useBillingPeriods() {
  const dispatch = useDispatch();
  const loading = useSelector(isLoading);
  const billingPeriods = useSelector(getBillingPeriods);

  useEffect(() => {
    dispatch(fetchBillingPeriods())
  }, []);

  return {
    loading,
    error: false === billingPeriods,
    billingPeriods: false === billingPeriods ? [] : Object.values(billingPeriods).filter(
      bp => 'CLOSED' === bp.status || Date.parse(bp.billing_period_end) < Date.now()
    ).sort(
      (a, b) => b.billing_period_start.localeCompare(a.billing_period_start)
    )
  };
}

function SupplierReportDownloads() {
  const { loading, error, billingPeriods } = useBillingPeriods();

  if (loading) {
    return <ReportMessage><Loading /></ReportMessage>;
  }

  if (error) {
    return <ReportMessage>There was a problem loading the reports</ReportMessage>;
  }

  if (0 === Object.keys(billingPeriods).length) {
    return <ReportMessage>You have no available reports</ReportMessage>;
  }

  return (
    <Col padded xs={12} lg={6}>
      {billingPeriods.map(bp => (
        <Box key={bp.billing_period_id}padded>
          <Row>
            <Col xs={6}>
	      {bp.billing_period_name}
            </Col>
            {bp.artifacts.filter(
              a => ['SUPPLIER-PRESALES-REPORT', 'SUPPLIER-SALES-REPORT'].includes(a.artifact_type)
            ).length === 0 ? <Col xs={6}>
              No available reports
            </Col> : <>
              <Col xs={3}>
                {bp.artifacts.filter(a => a.artifact_type === 'SUPPLIER-PRESALES-REPORT').map(a => (
                  <DownloadArtifact
                    key={a.billing_period_artifact_id}
                    filename={`Pre-Sales-${bp.billing_period_name.replaceAll(' ', '')}.csv`}
                    artifact_id={a.billing_period_artifact_id}
                  >
                    Pre-Sales Data
                  </DownloadArtifact>
                ))}
              </Col>
              <Col xs={3}>
                {bp.artifacts.filter(a => a.artifact_type === 'SUPPLIER-SALES-REPORT').map(a => (
                  <DownloadArtifact
                    key={a.billing_period_artifact_id}
                    filename={`Sales-${bp.billing_period_name.replaceAll(' ', '')}.csv`}
                    artifact_id={a.billing_period_artifact_id}
                  >
                    Sales Data
                  </DownloadArtifact>
                ))}
              </Col>
            </>}
          </Row>
        </Box>
      ))}
    </Col>
  );
}

export default SupplierReportDownloads;
